import React from 'react';
import './index.css';
import logo from './images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function App() {

  const socials = {
    //twitter: 'https://twitter.com/5050',
    discord: 'https://discord.gg/Jf7fbetPYB',
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white font-inter">
      <div className="text-center px-4">
        <img src={logo} alt="Logo" className="mx-auto mb-8 w-32 hover:scale-110 transition-transform duration-200" />
        <h1 className="text-5xl font-bold mb-4">Coming Soon</h1>
        <p className="text-lg mb-8">
          Our website is under construction. We'll be here soon with our new awesome site.
        </p>
        <div className="flex space-x-6 justify-center">
        {/* <a
            href={socials.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500 transition-colors duration-200"
          >
            <FontAwesomeIcon icon={['fab', 'twitter']} size="2x" />
          </a> */}
          <a
            href={socials.discord}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500 transition-colors duration-200"
            aria-label='Join our Discord to stay up to date with our latest news and updates.'
          >
            <FontAwesomeIcon icon={['fab', 'discord']} size="2x" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
